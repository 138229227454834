import Togglefy from 'togglefy';
import FogAnimation from './utils/FogAnimation';
import BadgesPos from './utils/BadgesPos';
import SVGRoad from './utils/SVGRoad';
import ModalManager from './utils/ModalManager';
import SwiperManager from './utils/SwiperManager';
import CardRenderer from './utils/CardRenderer';
import cardsData from './data/cardsData';
import './utils/demo';

const badgesPos = new BadgesPos();

const modalManager = new ModalManager(CardRenderer);

const swiperManager = new SwiperManager();

const clampElements = new Togglefy({
  targetSelector: '.clamp-element',
  triggerSelector: '.clamp-button',
  toggleClassName: 'unclamped',
  exclusive: true,
});

const fog = new FogAnimation({
  pngTexture: new URL('./assets/images/texture.png', import.meta.url),
  container: '.hero-background',
});

const svgRoad = new SVGRoad(Array.from(document.querySelectorAll('.pyramid-progress, .pyramid-decor')));

const app = () => {
  svgRoad.dashoffset(100);
  svgRoad.dasharray(100);

  badgesPos.init();

  modalManager.init();

  clampElements.init();

  fog.init();

  CardRenderer.init(cardsData);

  SwiperManager.createTrigger(
    '(min-width: 1200px)',
    () => swiperManager.initSwiper(),
    () => swiperManager.destroySwiper(),
  );
};

document.addEventListener('DOMContentLoaded', () => app());
